<template>
  <div class="login_box login_background-color">
    <div class="form_box login_background-color">
      <el-form
        label-position="left"
        :model="loginForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="70px"
        ref="loginForm"
      >
        <el-form-item label="用户名" prop="account">
          <el-input v-model="loginForm.account" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="loginForm.password" />
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button @click="submitForm('loginForm')" type="primary">
            登陆
          </el-button>
          <el-button @click="registerForm('loginForm')" type="success">
            注册
          </el-button>
          <!--          <el-button @click="resetForm('loginForm')">清空</el-button>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { showMsg } from '../util/msg';

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        account: '',
        password: '',
      },
      rules: {
        account: [
          { required: true, message: '用户名不能为空！', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '用户名最短3位最长20位！',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '密码不能为空！', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '密码最短3位最长20位！',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.axios
            .post('/auth/login', {
              username: this.loginForm.account,
              password: this.loginForm.password,
            })
            .then(res => {
              localStorage.setItem('tokenName', res.data.access_token);
              window.sessionStorage.setItem('login', 'yes');
              showMsg('success', '登陆成功，即将跳转！', false, 1000);
              setTimeout(() => this.$router.push('/dashboard/workbench'), 1000);
            });
          // .catch(err => {
          //   let message = '';
          //   if (err.response.status === 500 || err.re.status === 404)
          //     message = '服务器故障！';
          //   else if (err.response.status === 405)
          //     message = '登陆失败，请检查您的用户名和密码！';
          //   showMsg('success', message, false);
          // });
        }
      });
      // setTimeout(() => this.$router.push('/dashboard/workbench'), 600);
      // this.$refs[formName].validate(valid => {
      //   if (valid) {
      //     this.axios
      //       .post("/api/session", {
      //         AccountID: this.loginForm.account,
      //         Password: this.loginForm.password
      //       })
      //       .then(() => {
      //         // console.log(res);
      //         window.sessionStorage.setItem("login", "yes");
      //         this.$message({
      //           message: "登陆成功，即将跳转！",
      //           duration: 600,
      //           type: "success"
      //         });
      //         setTimeout(() => this.$router.push("/dashboard/1"), 600);
      //       })
      //       .catch(err => {
      //         let message = "";
      //         if (err.response.status === 500 || err.re.status === 404) message = "服务器故障！";
      //         else if (err.response.status === 400)
      //           message = "登陆失败，请检查您的用户名和密码！";
      //         this.$message({
      //           message: message,
      //           duration: 1000,
      //           type: "error"
      //         });
      //         // console.log(err.response);
      //       });
      //   }
      // });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    registerForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.axios({
            url: '/accounts',
            method: 'post',
            data: {
              name: this.loginForm.account,
              password: this.loginForm.password,
            },
          });
          showMsg('success', '注册成功，请登录！', true, 600);
        }
      });
    },
  },
  created() {
    // showMsg('info', '未注册用户进行登陆即注册账号', false, 2000);
  },
};
</script>

<style scoped>
.login_background-color {
  background-color: antiquewhite;
}
.login_box {
  height: 100%;
  width: 100%;
}
.form_box {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
